import React from "react";
import {useSelector} from "react-redux";
import './modal-black-background.css'
import {Button, Modal} from "react-bootstrap";
import {isUndefined} from "lodash/lang";
import {Icon} from "@iconify/react";

const ModalBlackBackground = (props) => {
    const {cancel} = useSelector(state => state.lots.phrases);
    const {
        setShow, sendForm, show = false, phraseTitle = '', phraseSubmit = '',
        showButtonFooter = true
    } = props

    const onSubmit = () => {
        content.classList.remove('modal-open');
        sendForm();
    }

    const onCancel = () => {
        content.classList.remove('modal-open');
        setShow()
    }

    let modalFooter = null;

    if (showButtonFooter) {
        modalFooter = (
            <Modal.Footer>
                <Button variant="secondary" className={'mx-1'} onClick={onSubmit}>{(phraseSubmit.length === 0)? <Icon icon="dashicons:saved" /> : phraseSubmit}</Button>
                <Button variant="secondary" className={'mx-1'} onClick={onCancel}>{cancel}</Button>
            </Modal.Footer>
        );
    }


    let modalHeader = null;

    if (phraseTitle.length > 0) {
        modalHeader = (
            <Modal.Header>
                <Modal.Title className={'col-12 bg-rgreen border rounded border-rg pt-2'}>
                <span className='h6 text-white d-flex font-weight-bold'>
                    {phraseTitle}

                    <button type="button" className="close mr-0"
                            onClick={() => onCancel()}>
                                    <span aria-hidden="true">&times;</span>
                    </button>
                </span>
                </Modal.Title>
            </Modal.Header>
        );
    }

    let modalBody = null;

    if (!isUndefined(props.children)){

        modalBody = (
            <Modal.Body>
                {props.children}
            </Modal.Body>
        );

    }

    const content = document.querySelector('html');

    (show)
        ? content.classList.add('modal-open')
        : content.classList.remove('modal-open');

    if (!show) {
        return null
    }

    return (
        <Modal
            size="lg"
            show={show}
            onHide={onCancel}
            className={'bootstrapiso modal-black-background'}
        >
            {modalHeader}
            {modalBody}
            {modalFooter}
        </Modal>

    )
}
export default ModalBlackBackground