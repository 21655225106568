// import React from 'react';
// import {useSelector} from "react-redux";
// import ModalImageGallery from "../modals/modal-image-gallery";
// import ModalSortingSettings from "../modals/modal-sorting-settings";
// import ModalPrefix from "../modals/modal-prefix";
// import ModalSubscriptionPrefix from "../modals/modal-subscription-prefix/modal-subscription-prefix";
// import ModalSubscriptionCategory from "../modals/modal-subscription-category";
// import ModalPromotionLots from "../modals/modal-promotion-lots";
// import ModalCategorySettings from "../modals/modal-category-settings/modal-category-settings";
// import ModalInlineModCheck from "../modals/modal-inline-mod-check";
//
// const Modals = () => {
//     const {
//         modalInlineShow, modalFollowPrefixShow, modalSortingSettingShow,
//         modalPrefixShow, modalFollowCategoryShow, modalPromotionShow, modalCategorySettingsShow, modalImageGalleryShow,
//     } = useSelector(state => {
//         const {
//             modalSubscriptionPrefixReducer,
//             sortingSetting,
//             modalSubscriptionCategoryReducer,
//             modalPromotion,
//             imageGalleryReducer,
//             categorySettingsReducer,
//             modalPrefix,
//             modal,
//         } = state
//
//         return {
//             modalInlineShow: modal.modalShow,
//             modalFollowPrefixShow: modalSubscriptionPrefixReducer.modalShow,
//             modalSortingSettingShow: sortingSetting.modalShow,
//             modalFollowCategoryShow: modalSubscriptionCategoryReducer.modalShow,
//             modalCategorySettingsShow: categorySettingsReducer.modalShow,
//             modalPrefixShow: modalPrefix.modalShow,
//             modalImageGalleryShow: imageGalleryReducer.modalShow,
//             modalPromotionShow: modalPromotion.modalShow,
//         }
//     });
//
//     return (
//         <>
//             {modalImageGalleryShow ? <ModalImageGallery/> : null}
//             {modalSortingSettingShow ? <ModalSortingSettings/> : null}
//             {modalPrefixShow ? <ModalPrefix/> : null}
//             {modalFollowPrefixShow ? <ModalSubscriptionPrefix/> : null}
//             {modalFollowCategoryShow ? <ModalSubscriptionCategory/> : null}
//             {modalPromotionShow ? <ModalPromotionLots/> : null}
//             {modalCategorySettingsShow ? <ModalCategorySettings/> : null}
//             {modalInlineShow ? <ModalInlineModCheck/> : null}
//         </>
//     )
//
// }
// export default Modals

import React, { Suspense, lazy } from 'react';
import { useSelector } from 'react-redux';

// ������� �������������� ��������� ����
const ModalImageGallery = lazy(() => import('../modals/modal-image-gallery'));
const ModalSortingSettings = lazy(() => import('../modals/modal-sorting-settings'));
const ModalPrefix = lazy(() => import('../modals/modal-prefix'));
const ModalSubscriptionPrefix = lazy(() => import('../modals/modal-subscription/modal-subscription-prefix/modal-subscription-prefix'));
const ModalSubscriptionCategory = lazy(() => import('../modals/modal-subscription/modal-subscription-category/modal-subscription-category'));
const ModalPromotionLots = lazy(() => import('../modals/modal-promotion-lots'));
const ModalCategorySettings = lazy(() => import('../modals/modal-category-settings/modal-category-settings'));
const ModalInlineModCheck = lazy(() => import('../modals/modal-inline-mod-check'));

const Modals = () => {
    const {
        modalInlineShow, modalFollowPrefixShow, modalSortingSettingShow,
        modalPrefixShow, modalFollowCategoryShow, modalPromotionShow, modalCategorySettingsShow, modalImageGalleryShow,
    } = useSelector(state => {
        const {
            modalSubscriptionPrefix ,
            sortingSetting,
            modalSubscriptionCategory,
            modalPromotion,
            imageGalleryReducer,
            categorySettingsReducer,
            modalPrefix,
            modal,
        } = state

        return {
            modalInlineShow: modal.modalShow,
            modalFollowPrefixShow: modalSubscriptionPrefix .modalShow,
            modalSortingSettingShow: sortingSetting.modalShow,
            modalFollowCategoryShow: modalSubscriptionCategory.modalShow,
            modalCategorySettingsShow: categorySettingsReducer.modalShow,
            modalPrefixShow: modalPrefix.modalShow,
            modalImageGalleryShow: imageGalleryReducer.modalShow,
            modalPromotionShow: modalPromotion.modalShow,
        }
    });

    return (
        <Suspense fallback={<div>Loading...</div>}>
            {modalImageGalleryShow ? <ModalImageGallery /> : null}
            {modalSortingSettingShow ? <ModalSortingSettings /> : null}
            {modalPrefixShow ? <ModalPrefix /> : null}
            {modalFollowPrefixShow ? <ModalSubscriptionPrefix /> : null}
            {modalFollowCategoryShow ? <ModalSubscriptionCategory /> : null}
            {modalPromotionShow ? <ModalPromotionLots /> : null}
            {modalCategorySettingsShow ? <ModalCategorySettings /> : null}
            {modalInlineShow ? <ModalInlineModCheck /> : null}
        </Suspense>
    );
}

export default Modals;
