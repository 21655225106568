import React, {useContext, useMemo} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getFormattedDate} from "../../../redux/lots/action";
import {lotContext} from "../../auction-lots-listing/components/lots-list-item/lots-list-item";

const FormTake = () => {

    const dispatch = useDispatch();
    const {lotData} = useContext(lotContext);

    const {
        phrases: {ak_au_ph_lot_send_for_you},
        time_wait_take,
    } = useSelector(state => state.lots);

    const {take_date} = lotData;

    return useMemo(() => {
        const finish_time_for_take = dispatch(getFormattedDate(time_wait_take + take_date));
        const lot_send_for_you = ak_au_ph_lot_send_for_you.replace(/\{finish_time_for_take\}/, finish_time_for_take);

        return (
            <div>
                <span dangerouslySetInnerHTML={{__html: lot_send_for_you}}/>
            </div>
        );
    }, [take_date, dispatch]);

}
export default FormTake