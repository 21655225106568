import React, {useContext} from 'react';
import './lots-item-icons.css';

import {Icon} from "@iconify/react";
import clockwiseLeftRightArrows from "@iconify/icons-emojione-v1/clockwise-left-right-arrows";
import pushpinIcon from "@iconify/icons-noto/pushpin";
import loopIcon from "@iconify/icons-flat-ui/loop";
import bulbIcon from "@iconify/icons-flat-ui/bulb";
import glassesIcon from '@iconify/icons-emojione-v1/glasses';
import {setFilter} from "../../../redux/sidebars/filter-sidebar/actionFilterSideBar";
import {useDispatch, useSelector} from "react-redux";
import {lotContext} from "../../auction-lots-listing/components/lots-list-item/lots-list-item";
import {getLinkData} from "../../../redux/lots/action";
import {useNavigate} from "react-router-dom";

const LotsItemIcons = () => {
    const {lotData} = useContext(lotContext);
    const {
        phrases: {
            ak_au_phrase_Interesting_lot,
            ak_au_phrase_multilot,
            ak_au_phrase_promotion,
            ak_au_phrase_auto_redit_lot,
            ak_au_ph_tracked_lot
        },
        visitor: {
            user_id,
            permissions: {
                auctions: {
                    view_all_bids
                }
            }
        }
    } = useSelector(state => state.lots);

    const {
        status,
        auto_reedit_lot,
        is_interesting,
        status_promotion,
        lot_type,
        balance,
        watch_user_id
    } = lotData;

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const setFilterPromotion = () => {
        dispatch(setFilter({name: 'promotion', value: 1}))
        const queryLink = dispatch(getLinkData({page: 1}));
        navigate(`?${queryLink}`);
    }

    const auto_redit_icon = (auto_reedit_lot && status === 'active') ?
        <span className="mr-1" title={ak_au_phrase_auto_redit_lot}>
                    <Icon icon={clockwiseLeftRightArrows}/>
                </span> : null;

    const watch_icon = (user_id === watch_user_id && status === 'active') ?
        <span className="mr-1 glassesIcon" title={ak_au_ph_tracked_lot}>
                    <Icon icon={glassesIcon}/>
                </span> : null;

    const Interesting_lot_icon = (status === 'active' && view_all_bids && is_interesting) ?
        <span className="mx-1" title={ak_au_phrase_Interesting_lot}>
                    <Icon icon={pushpinIcon}/>
                </span> : null;

    const multi_lot_icon = (lot_type === 1 && balance > 0) ?
        <span className="mx-1" title={ak_au_phrase_multilot}>
                    <Icon icon={loopIcon}/>
                </span> : null;

    const promotion_icon = (status === 'active' && status_promotion) ?
        <span className="mx-1" title={ak_au_phrase_promotion} onClick={() => setFilterPromotion()}>
                    <Icon icon={bulbIcon}/>
                </span> : null;

    return (
        <div className="d-flex">
            {auto_redit_icon}
            {Interesting_lot_icon}
            {multi_lot_icon}
            {promotion_icon}
            {watch_icon}
        </div>
    );
}

export default LotsItemIcons

