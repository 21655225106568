import {
    DELETE_CHANGE_LOT,
    GET_DFAULT_LOTS_LIST_PARAMS,
    LOTS_LOADING, ON_CHANGE_YEARS,
    ON_SUBMIT_SEARCH, ON_TOGGLE_ALL_LOTS, SET_ALL_CATEGORIES, SET_USER_PREFIXES,
    SET_CHANGE_LOT, SET_MODAL, SET_PAGE, SET_TYPE,
    UPDATE_LOTS_DATA
} from "../types";

const initialState = {
    user_owner_data: {},
    lotsData: [], // -- lot objects -- //
    all_categories: {}, // -- auction categories -- //
    owner_lots_categories: [], // -- ��������� � ������ ���� ���� auction_category_id, lot_in_category - ���-�� ����� � ���������
    loading: true, // -- loading lots data -- //
    startLoading: true, // -- loading lots data -- //
    simulateLoading: false,
    filterTabNav: (localStorage.getItem('filterTab') === null) ? 'list' : localStorage.getItem('filterTab'), // -- tab view (list, grid, menu) -- //
    user_id: parseInt(document.getElementById('user_id').value),
    visitor_user_id: parseInt(document.getElementById('visitor_user_id').value),

    perPage: 0,
    total: 0,
    csrfToken: document.getElementById('csrf_token_page').value,
    ignore_view: 0,
    css_classes_prefix: {},
    visitor: {}, // -- visitor data -- //
    users: {},
    phrases: {},
    changeLots: [], // -- selected lot objects -- //
    modalShow: false, // -- show modal options for lots --//
    changeAllLots: false,
    rating_values: {},
    time_wait_pay: '',
    time_wait_send: '',
    time_wait_take: '',
    users_payment_methods: {},
    topBiddersDeliveryMethod: {},
    payment_methods: {},
    imagePath: 'styles/default',
    xenOptions: {},
    languages: [],
    years: [],
    prefixes_in_categories: {},
    user_prefixes: [],
    onlineStaff: [],
    userAuction: {},
    totalMultiLots: 0,
    totalSimpleLots: 0,
    totalAllLots: 0,
}

export const lotsReducer = (state = initialState, action) => {

    switch (action.type) {
        case GET_DFAULT_LOTS_LIST_PARAMS:
            return {...state, ...action.payload};
        case UPDATE_LOTS_DATA:
            return {...state, ...action.payload};
        case LOTS_LOADING:
            return {...state, loading: action.payload};
        case ON_SUBMIT_SEARCH:
            return {...state, search: action.payload};
        case SET_MODAL:
            return {...state, modalShow: action.payload};
        case SET_CHANGE_LOT:
            return {...state, changeLots: action.payload};
        case DELETE_CHANGE_LOT:
            return {...state, changeLots: action.payload};
        case ON_TOGGLE_ALL_LOTS:
            return {...state, changeLots: action.payload};
        case SET_PAGE:
            return {...state, page: action.payload};
        case ON_CHANGE_YEARS:
            return {...state, year_change: action.payload};
        case SET_TYPE:
            return {...state, type: action.payload};
        case SET_ALL_CATEGORIES:
            return {...state, all_categories: action.payload}
        case SET_USER_PREFIXES:
            return {...state, user_prefixes: action.payload}
        default:
            return state;
    }


}