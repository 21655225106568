import {
    ON_CHANGE_ACTION,
    ON_CHANGE_CATEGORY,
    ON_CHANGE_COPY_ACTION,
    ON_CHANGE_REMOVE_ACTION, SET_ERRORS, SET_OPTIONS,
    TOGGLE_MODAL
} from "../../types";

const initialState = {
    handler_type: 0,
    category_id: null,
    operation_copy: null,
    delete_type: null,
    errors: [],
    modalShow: false,
    option: [],
    delete_reason: ''
};

export const modalReducer = (state = initialState, action) => {

    switch (action.type) {
        case ON_CHANGE_ACTION:
            return {...state, handler_type: action.payload}
        case SET_ERRORS:
            return {...state, errors: action.payload}
        case ON_CHANGE_CATEGORY:
            return {...state, category_id: action.payload}
        case ON_CHANGE_COPY_ACTION:
            return {...state, operation_copy: action.payload}
        case ON_CHANGE_REMOVE_ACTION:
            return {...state, ...action.payload}
        case TOGGLE_MODAL:
            return {...state, modalShow: action.payload}
        case SET_OPTIONS:
            return {...state, option: action.payload}
        default:
            return state;
    }
}