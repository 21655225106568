import React, {createContext} from 'react';
import './lots-list-item.css';


import useLotListItemFn from "./useLotListItemFn";
import LotsListDefaultItem from "./lots-list-default-item";
const lotContext = createContext();
export {lotContext}
const LotsListItem = (props) => {

    const {
        promotion,
        mainClazz,
        lotData,
        lotThumbnail,
        lotHeaderInfo
    } =  useLotListItemFn(props);


    return (
        <lotContext.Provider value={{
            promotion,
            mainClazz,
            lotData,
            lotThumbnail,
            lotHeaderInfo
        }}>

            <LotsListDefaultItem/>
        </lotContext.Provider>
    );
};

export default LotsListItem;