import React, {useContext} from 'react'
import LotThumbnail from "../../../lot-components/lot-thumbnail";
import LotHeaderInfo from "../../../lot-components/lot-header-info";
import AdminLotButtons from "../../../lot-components/admin-lot-buttons";
import ThirdBlock from "./third-block";
import FourthBlock from "./fourth-block";
import BidAndBuyNowBlock from "../../../lot-components/bids-and-buynow-block";
import AnswerForm from "./answer-form";
import {lotContext} from "./lots-list-item";
import {LotsContext} from "../../auction-lots-listing ";

const LotsListDefaultItem = () => {
    const {
        promotion,
        mainClazz,
        lotThumbnail,
        lotHeaderInfo
    } = useContext(lotContext)

    const {show_img_lots_list} = useContext(LotsContext);

    return (
        <div style={promotion} className={mainClazz}>

            <div className="row align-items-center">
                {show_img_lots_list ?
                    <div className="col-lg-2 col-sm-3 col-12">
                        <div className="row justify-content-center">
                            <LotThumbnail {...lotThumbnail}/>
                        </div>
                    </div> : null}

                <div className={!show_img_lots_list ? 'col-12' : 'col-lg-10 col-sm-9 col-12'}>
                    <div className="row justify-content-center">
                        <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 px-sm-1">
                            <LotHeaderInfo {...lotHeaderInfo}/>
                            <AdminLotButtons/>
                        </div>

                        <div
                            className="col-xl-2 col-lg-4 col-md-4 col-sm-4 col-12 py-2 py-sm-0 px-sm-2 align-self-center text-muted">
                            <ThirdBlock/>
                        </div>

                        <div className="col-xl-2 col-lg-4 col-md-4 col-sm-3 col-5 px-sm-2 align-self-center text-muted">
                            <FourthBlock/>
                        </div>

                        <div className="col-xl-2 col-lg-4 col-md-4 col-sm-5 col-7 px-sm-2 align-self-center text-muted">
                            <BidAndBuyNowBlock/>
                        </div>
                    </div>
                </div>
                <AnswerForm/>
            </div>
        </div>
    )
}

export default LotsListDefaultItem