import React, {createContext, useMemo} from "react"
import './auction-lots-listing.css';
import MainLotView from "./components/main-lots-view";
import LotsListItem from "./components/lots-list-item";
import useLotListFn from "./useLotListFn";

const LotsContext = createContext();
export {LotsContext};
const AuctionLotsListing = () => {

    const {
        show_img_lots_list,
        changeLots,
        onToggleLots,
        showImage,
        auction_categories,
        type,
        user_lots_type,
        lotsData,
        phrases,
        promotion_color
    } = useLotListFn()

    const {you_have_no_auctions, no_lots_sold_or_hidden} = phrases;

    const lotList = useMemo(() => {

        if ((lotsData.length === 0)) {
            return <div>{type === 'sale' ? no_lots_sold_or_hidden : you_have_no_auctions}</div>
        }

        return lotsData.map((lotData) => (
            <LotsListItem
                key={type === 'all_user_bets' ? lotData.bid_id : lotData.auction_id}
                lotData={lotData}
            />))
    }, [lotsData])

    return (
        <LotsContext.Provider value={{
            show_img_lots_list,
            onToggleLots,
            showImage,
            auction_categories,
            type,
            user_lots_type,
            promotion_color,
            phrases,
            changeLots
        }}>
            <MainLotView>
                {lotList}
            </MainLotView>
        </LotsContext.Provider>
    );
};

export default AuctionLotsListing;
