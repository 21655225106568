import React, {useMemo} from 'react'
import {isEmpty} from "lodash/lang";
import Spinner from "../../spinner";
import {forIn} from "lodash/object";

import {useSelector} from "react-redux";
import AdvertisingLot from "./advertising-lot";
import './advertising-block.css'

const AdvertisingBlock = (props) => {

    const {
        lots = {},
        blockTitle = '',
        viewAllLink='',
        lotFilter = ()=>{
            console.log('filter set')
        }
    } = props

    const {
        phrases: {ak_au_view_all}
    } = useSelector((state) => {
        return state.lots
    })

    const lotsBlock = useMemo(() => {
        if (isEmpty(lots)) return <Spinner/>

        let lotsComponent = []
        forIn(lots, (val, key) => {
            let lot = <AdvertisingLot key={key} {...val}/>
            lotsComponent = [...lotsComponent, lot]
        })

        return lotsComponent
    }, [lots])

    const setFilterLot = (e) => {
        e.preventDefault();
        lotFilter()
    }

    const linkTitleBlock = useMemo(() => {

        if (blockTitle.length > 0) {
            return null
        }

        return (
            <a className='text-rg' href={viewAllLink}
               onClick={(e) => setFilterLot(e)}>
                {ak_au_view_all}
            </a>
        )

    }, [blockTitle])

    return (
        <div className="row justify-content-center mb-1 d-none d-xl-block">
            <div className="col-12">
                <div className="section-main">
                    <div className="row m-2 border-bottom px-2">
                        <div className="col-6 p-0">
                            <div className="h6 text-left font-weight-bold text-rg">{blockTitle}</div>
                        </div>
                        <div className="col-6 p-0">
                            <div className="float-right">
                                {linkTitleBlock}
                            </div>
                        </div>
                    </div>
                    <div id='advertising-block' className="row justify-content-center m-1 overflow-hidden">
                        {lotsBlock}
                    </div>
                </div>
            </div>
        </div>
    )
}
export default AdvertisingBlock