import React, {memo, useContext} from 'react';
import LotTitle from "../lot-title";
import LotsItemIcons from "../lots-item-icons";
import UserCreateDateBlock from "../user-create-date-block";
import CategoryLineBlock from "../category-line-block";
import LotShortDescription from "../lot-short-description";
import ReasonDeleteLot from "./reason-delete-lot";
import {lotContext} from "../../auction-lots-listing/components/lots-list-item/lots-list-item";
import {LotsContext} from "../../auction-lots-listing/auction-lots-listing ";

const LotHeaderInfo = (props) => {

    const MemoizedLotsItemIcons = memo(LotsItemIcons);
    const MemoizedLotShortDescription = memo(LotShortDescription);
    const MemoizedCategoryLineBlock = memo(CategoryLineBlock);
    const MemoizedReasonDeleteLot = memo(ReasonDeleteLot);
    const {lotData} = useContext(lotContext);
    const {show_img_lots_list} = useContext(LotsContext);

    return (
        <div className="d-flex flex-column">
            <MemoizedLotsItemIcons/>
            <LotTitle {...props}/>
            {show_img_lots_list ? <MemoizedLotShortDescription/>: null}
            <MemoizedCategoryLineBlock categories={props.categories}/>
            <UserCreateDateBlock />
            {lotData.isdel ? <MemoizedReasonDeleteLot {...lotData}/> : null}
        </div>
    );
}
export default LotHeaderInfo