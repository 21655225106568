import React, {useContext, useMemo} from 'react';
import {useSelector} from "react-redux";
import {lotContext} from "../../auction-lots-listing/components/lots-list-item/lots-list-item";

const LotStatusBlock = () => {

    const {lotData} = useContext(lotContext);
    const{expired_flag, status, copy} = lotData;

    const {
        phrases: {ak_au_lot_nofix_expired, ak_au_lot_fix_expired, ak_au_copy_will_be_deleted}
    } = useSelector(state => state.lots);

    const phrase = useMemo(() => {
        let phrase = null;

        if (status === 'finalized') {
            phrase = ak_au_lot_fix_expired
        }
        if (status === 'nosale' && expired_flag) {
            phrase = ak_au_lot_nofix_expired
        }
        if (status === 'nosale' && copy === 1) {
            phrase = <span className="badge badge-danger"> {ak_au_copy_will_be_deleted}</span>
        }
        return phrase

    }, [status])

    if (phrase !== null) {
        return (
            <div className='d-flex flex-wrap justify-content-center text-muted align-items-sm-center'>
                {phrase}
            </div>
        );
    }

    return null;

}
export default LotStatusBlock