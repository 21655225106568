import React, {useContext} from 'react'
import {truncate} from "lodash/string";
import GlobalHelper from "../../../helpers/global-helper";
import {useDispatch, useSelector} from "react-redux";
import {lotContext} from "../../auction-lots-listing/components/lots-list-item/lots-list-item";
import {Link, useNavigate} from "react-router-dom";
import {SET_LOT_DATA} from "../../../redux/types";

const LinkTitle = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {lotData} = useContext(lotContext);
    const {title, lot_type, balance, isdel} = lotData;

    const { visitor: {permissions: {auctions: {see_new = false}}},
        phrases: {ak_au_phrase_lot_balance}} = useSelector(state => state.lots);

    const link = `/lots/${GlobalHelper.getLotVariableReference(lotData)}`;
    const title_short = truncate(title, {'length': 80, 'separator': '...'});
    const linkTitle = `${title_short} ${lot_type && balance > 0 ? ak_au_phrase_lot_balance.replace(/\{balance\}/, balance) : ''}`;
    const clazz = `lot-title ${isdel ? 'text-danger' : ''}`;

    const handleTitleClick = (event) => {
        if (see_new) { //todo delete this
            event.preventDefault();
            navigate(link);
        }
    }

    if (see_new) {
        return (
            <Link className={clazz} to={{
                pathname: `${link}`
            }} onClick={() => {
                dispatch({type: SET_LOT_DATA, payload: {auction: lotData}});
            }}>
                {linkTitle}
            </Link>
        )
    }

// todo ������� ����� �������� �� ����� ������.
    return (
        <a className={clazz}
           onClick={(e) => handleTitleClick(e)}
           href={link}>{linkTitle}</a>
    )
}
export default LinkTitle

