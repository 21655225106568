import {useEffect, useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {changeAllLots, setLotsData} from "../../redux/lots/action";
import {getFilters, setFilters} from "../../redux/sidebars/filter-sidebar/actionFilterSideBar";
import {toggleModal} from "../../redux/modals/modal-inline-mod-check/actionModal";
import {getQueryLinkParams} from "../../helpers/routing-helper";
import {includes} from "lodash/collection";
import useBreadCrumbsMainPage from "../global-hooks/breadcrums-hooks/useBreadCrumbsMainPage";
import useBreadCrumbsOtherPage from "../global-hooks/breadcrums-hooks/useBreadCrumbsOtherPage";
import useLotsDataWatcher from "./useLotsDataWatcher";
import {useLocation} from "react-router-dom";
import {isEmpty} from "lodash/lang";


const useLotsFn = () => {
    const dispatch = useDispatch();

    const location = useLocation();
    const {search: urlSearch, pathname} = location;

    const [isInitialized, setIsInitialized] = useState(false);

    // ��������� ������ ����������� ������ �� state
    const {startLoading, simulateLoading, changeLots, lotsData, lots, user_sort_data} = useSelector(state => ({
        startLoading: state.lots.startLoading,
        simulateLoading: state.lots.simulateLoading,
        changeLots: state.lots.changeLots,
        lotsData: state.lots.lotsData,
        user_sort_data: state.lots.user_sort_data,
        lots: state.lots,
    }));

    // ����������� query, ����� �������� ����������
    const {user_lots_type = ''} = useMemo(() => getQueryLinkParams(user_sort_data), [user_sort_data, urlSearch, pathname]);

    useEffect(() => {
        const initializeData = async () => {
            try {
                const filters = await dispatch(getFilters());
                await dispatch(setFilters(filters));
                await dispatch(setLotsData());
                setIsInitialized(true);  // ������������� ���������
            } catch (error) {
                console.error("������ ��� ������������� ������ �����:", error);
                setIsInitialized(false);
            }
        };

        initializeData();
    }, [urlSearch, pathname]);

    useLotsDataWatcher();

    // ������ ��� ��������� �����
    useEffect(() => {
        if (isInitialized) {
            dispatch(changeAllLots(changeLots.length === lotsData.length));
            if (changeLots.length === 0) {
                dispatch(toggleModal(false));
            }
        }
    }, [changeLots, isInitialized, lotsData.length, dispatch]);

    // ����������� ������� ��� ������� ������
    const setBreadCrumbsMainPage = useBreadCrumbsMainPage();
    const setBreadCrumbsOtherPage = useBreadCrumbsOtherPage();

    // ������ ��� ��������� ������� ������
    useEffect(() => {
        if (isInitialized) {
            if (user_lots_type === 'auction_lots' && !isEmpty(lots.all_categories)) {
                setBreadCrumbsMainPage();
            }

            if (!includes(['auction_lots', 'archive_lots'], user_lots_type)) {
                setBreadCrumbsOtherPage();
            }
        }
    }, [user_lots_type, isInitialized, lots.all_categories, setBreadCrumbsMainPage, setBreadCrumbsOtherPage]);


    return {
        startLoading, simulateLoading, changeLots, lotsData, user_lots_type,
    }
}
export default useLotsFn
