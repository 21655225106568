import React, {useContext, useMemo} from 'react';
import './bids-and-buynow-block.css';
import Helper from '../../../helpers/global-helper'
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {useSelector} from "react-redux";
import {LotsContext} from "../../auction-lots-listing/auction-lots-listing ";
import {includes} from "lodash/collection";
import {lotContext} from "../../auction-lots-listing/components/lots-list-item/lots-list-item";

const BidAndBuyNowBlock = () => {
    const {lotData} = useContext(lotContext);
    const {min_bid, top_bid, buyout_price, status, amount} = lotData
    const {phrases: {ak_au_buyout_price, price, ak_count_bid}} = useSelector(state => state.lots);
    const {type, user_lots_type} = useContext(LotsContext);

    return useMemo(() => {
        if (type === 'all_user_bets') {
            return (
                <div className="my-1 d-flex">
                    <span className="">{ak_count_bid}:</span>
                    <OverlayTrigger
                        placement={'bottom'}
                        overlay={
                            <Tooltip id="count_bid">
                                {ak_count_bid}
                            </Tooltip>
                        }>
                        <span className="new ml-xl-auto mx-1">{Helper.currencyFormat(amount)}</span>
                    </OverlayTrigger>
                </div>
            );
        }

        const cost = (top_bid === 0 || top_bid === null) ? min_bid : top_bid
        const statuses = ['active', 'no_pay', 'nosale', 'finalized', 'vacation', 'inactive_copy'];
        const priceCondition = includes(statuses, status);

        const buyoutPriceBlock = (buyout_price > 0 && priceCondition && user_lots_type !== 'archive_lots') ?
            <div className="my-1 d-flex">
                <span className="">{ak_au_buyout_price}:</span>
                <span className="new ml-xl-auto text-danger mx-1">
                {Helper.currencyFormat(buyout_price)}
            </span>
            </div> : null;


        if (cost == buyout_price && priceCondition) {
            return (<>{buyoutPriceBlock}</>);
        }

        return (
            <>
                <div className="my-1 d-flex">
                    {(priceCondition) ? <span className="">{price}:</span> : null}
                    <span className="new ml-xl-auto mx-1">{Helper.currencyFormat(cost)}</span>
                </div>
                {buyoutPriceBlock}
            </>
        )

    }, [type, amount, buyout_price, top_bid, min_bid, status, user_lots_type])
};

export default BidAndBuyNowBlock;