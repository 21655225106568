import React, {useContext} from 'react'
import {lotContext} from "../../auction-lots-listing/components/lots-list-item/lots-list-item";

const CheckBox = (props) => {
    const {lotData} = useContext(lotContext);
    const {auction_id} = lotData;
    const {onSelected, onToggleLot} = props

    return (
        <input checked={onSelected}
               className="custom-control-input"
               id={`lot_${auction_id}_title`}
               type="checkbox"
               value={auction_id}
               onChange={() => onToggleLot()}
               data-action
        />
    );
}
export default CheckBox