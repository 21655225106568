import React, {useContext} from 'react';
import BuyerOrSallerBlock from "../buyer-or-saler-block";
import {useSelector} from "react-redux";
import {lotContext} from "../../auction-lots-listing/components/lots-list-item/lots-list-item";

const BuyersBlock = () => {

    const {phrases:{buyer}} = useSelector(state => state.lots);
    const {lotData} = useContext(lotContext);
    const {top_bidder, top_bidder_name} = lotData;

    return (
        <BuyerOrSallerBlock
            word={buyer}
            name={top_bidder_name}
            user_id={top_bidder}
            rating={false}
        />
    );
}

export default BuyersBlock