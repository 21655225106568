import React from 'react';
import './category-line-block.css';
import CategoryLinkComponent from "../../repeating-components/wrapper-components/category-link-component";
import {isUndefined} from "lodash/lang";

const CategoryLineBlock = ({categories}) => {

    if (isUndefined(categories)){
        return null
    }

    return (
        <div className="category-line-link-block muted">
            {categories.map((item, i) => (
                <span key={i} className="item-category-link">
                <CategoryLinkComponent item={item} className=''>
                    {item.category_title}
                </CategoryLinkComponent>
            </span>
            ))}
        </div>
    );
};

export default CategoryLineBlock;