import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {UPDATE_LOTS_DATA} from "../../redux/types";
import {useNavigate} from "react-router-dom";
import {isNull} from "lodash/lang";
import {installFilters} from "../../redux/sidebars/filter-sidebar/actionFilterSideBar";
import queryString from "query-string";

const useLotsDataWatcher = () => {

    const {lotsData} = useSelector(state => state.lots);

    const linksData = [
        {
            name: 'linkLogo',
            selector: document.querySelector('div#logo div a')
        },
        {
            name: 'linkTab',
            selector: document.querySelector('#navigation li.navTab.auctions.selected a.navLink')
        },
        {
            name: 'navLinkNewLots',
            selector: document.querySelector('#navigation ul.secondaryContent.blockLinksList li a.ak_au_new_lots')
        },
        {
            name: 'linkYourBids',
            selector: document.querySelector('#navigation ul.secondaryContent.blockLinksList li a.ak_au_your_bids')
        },
    ];

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (lotsData) {
            linksData.map((item) => setFunctionMainLink(item));
        }
    }, [lotsData]);

    const setFunctionMainLink = ({name, selector}) => {
        if (!isNull(selector)) {
            selector.onclick = (e) => {
                e.preventDefault();

                if (name === 'navLinkNewLots') {
                    dispatch(installFilters({name: 'filter_new_lots', value: 1}));
                }

                setSimulateLoading(true);
                setTimeout(setSimulateLoading, 1000)

                if (name === 'linkYourBids') {

                    const obj = {
                        order: 'title',
                        direction: 'asc',
                        type: 'for_sales',
                        user_lots_type: 'user_bidding'
                    }
                    const data = queryString.stringify(obj);
                    navigate(`/auction-history/user-bidding?${data}`);

                    return true
                }

                navigate(`/`);
            }
        }

        return true;
    }

    const setSimulateLoading = (val = false) => {
        dispatch({type: UPDATE_LOTS_DATA, payload: {simulateLoading: val}});
    }
}
export default useLotsDataWatcher