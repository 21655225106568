import {isUndefined, isNaN} from "lodash/lang";
import {includes, orderBy} from "lodash/collection";
import {SET_ATTACHMENTS_FORM_DISPUT_MESSAGE, SET_LOT_DATA, UPDATE_AUCTION_OPTION} from "../types";
import {getGlobalParams} from "../lots/action";
import LotPageApiService from "../../services/lot-page-api-service";
import CrudApiService from "../../services/crud-api-service";
import PromotionApiService from "../../services/promotion-api-service";
import ProcessApiService from "../../services/process-api-service";
import InlineModApiService from "../../services/inline-mod-api-service";
import LotsApiService from "../../services/lots-api-service";
import {setParamsSetDateModal} from "../modals/modal-set-date/actionSetDate";
import {setParamsSetEndTimeModal} from "../modals/modal-set-end-time/actionSetEndTime";
import {setParamsTitleModal} from "../modals/modal-set-title/actionSetTitle";
import {setParams} from "../modals/modal-remove-lot/actionRemoveLot";
import {setParamsFinishLotModal} from "../modals/modal-finish-lot/actionFinishLot";
import {setParamsRemoveLotFromSale} from "../modals/modal-remove-from-sale/actionRemoveLotFromSale";
import {getSideBarDefaultParams} from "../sidebars/actionSideBar";
import {getQueryLinkParams} from "../../helpers/routing-helper";


const crudApiService = new CrudApiService()
const processApiService = new ProcessApiService()
const lotsApiService = new LotsApiService();
const lotPageApiService = new LotPageApiService();
const promotionApiService = new PromotionApiService();

const {setParamsInlineBlock} = new InlineModApiService();

export const fetchAllLotPageData = () => {
    return async (dispatch) => {
        await dispatch(getSideBarDefaultParams());
        await dispatch(getGlobalParams());
        await dispatch(getLotData());
    };
};

export function getLotData() {
    return async (dispatch) => {
        const {auction_id, disput_id} = getQueryLinkParams();

        let obj = {auction_id};

        if (!isNaN(disput_id)) {
            obj.disput_id = disput_id;
        }

        await lotPageApiService.getLotData(obj).then((json) => {

            const {
                disput_messages, disput_history, auction, count_watch_users, watch_users, open_edit,
                allCountViewerLot, ignore_view, view_offer_button, user, lot_payment_methods, lot_delivery_methods,
                prefixes, all_bids, autobids, max_autobid, lastBidForDelete, lot_history, top_bidder_data, questions,
                disput, watched_dispute, finish_time_for_pay, finish_time_for_send, finish_time_for_take, similarLots,
                can_delete_user_bid, last_max_bid, payment_creating_copy = 0, promotionPrice = 0, pay_for_reedit_lot = 0
            } = json;

            const disputMessage = orderBy(disput_messages, ['time_message'], ['asc'])
            const disputHistory = orderBy(disput_history, ['lot_status_date'], ['asc'])
            const watchUsers = orderBy(watch_users, ['watch_id'], ['asc'])
            const lotPaymentMethods = orderBy(lot_payment_methods, ['payment_id'], ['asc'])
            const lotDeliveryMethods = orderBy(lot_delivery_methods, ['payment_id'], ['asc'])
            const allBids = orderBy(all_bids, ['bid_id'], ['asc'])
            const offers = orderBy(json.offers, ['offer_id'], ['asc'])
            const autoBids = orderBy(autobids, ['autobid_date'], ['asc'])
            const lotHistory = orderBy(lot_history, ['lot_status_date'], ['asc'])


            const payload = {
                user,
                disput,
                offers,
                auction,
                allBids,
                autoBids,
                prefixes,
                open_edit,
                questions,
                max_autobid,
                ignore_view,
                similarLots,
                last_max_bid,
                promotionPrice,
                watched_dispute,
                lastBidForDelete,
                count_watch_users,
                view_offer_button,
                allCountViewerLot,
                lotPaymentMethods,
                lotDeliveryMethods,
                can_delete_user_bid,
                finish_time_for_pay,
                finish_time_for_send,
                finish_time_for_take,
                payment_creating_copy,
                lotHistory,
                topBidderData: top_bidder_data,
                disput_messages: disputMessage,
                disput_history: disputHistory,
                watch_users: watchUsers,

                loadingPage: false
            }

            dispatch({type: SET_LOT_DATA, payload});
        })
    }
}

export function setAttachmentsDisputMessage(attachments) {
    return (dispatch) => {
        dispatch({type: SET_ATTACHMENTS_FORM_DISPUT_MESSAGE, payload: attachments})
    }
}

export function removeImages(attachment) {
    return () => {
        const {temp_hash, attachment_id, content_type} = attachment;
        const obj = {temp_hash, attachment_id, content_type};
        crudApiService.deleteImage(obj).then((json) => {
            const {_redirectStatus} = json

            if (_redirectStatus === 'ok') {
                return true;
            }
        });
    }
}

export function getDataAutoPromotionForm() {
    return async (dispatch, getState) => {
        const {auction: {auction_id: id}} = getState().lotPage;

        return await promotionApiService.getDataAutoPromotionForm({id}).then((json) => {
            return json;
        });
    }
}

export function setAutoPromotion() {
    return async (dispatch, getState) => {

        const {auction: {auction_id: id}} = getState().lotPage;
        const obj = {_xfConfirm: 1, id}

        return await promotionApiService.setAutoPromotion(obj).then((json) => {
            const {_redirectStatus, _redirectMessage} = json

            if (_redirectStatus === 'ok') {
                dispatch(getLotData())
            }

            if ('auction' in _redirectMessage) {
                return {auction: _redirectMessage.auction, _redirectStatus};
            }
        });
    }
}

export function disabledAutoPromotion() {
    return async (dispatch, getState) => {

        const {auction: {auction_id: id}} = getState().lotPage;
        const obj = {_xfConfirm: 1, id}

        return await promotionApiService.disabledAutoPromotion(obj).then((json) => {
            const {_redirectStatus, _redirectMessage} = json

            if (_redirectStatus === 'ok') {
                dispatch(getLotData())
            }

            if ('auction' in _redirectMessage) {
                return _redirectMessage.auction;
            }
        });
    }
}


export const updateAuctionSettings = (settings) => ({
    type: UPDATE_AUCTION_OPTION,
    payload: settings,
});


export const setLotNewEndDate = () => {
    return async (dispatch, getState) => {

        const {auction: {auction_id}} = getState().lotPage;
        const {handler_type} = getState().modal;

        const {sendData, newChosenDate, modalShow} = getState().modalSetDate;
        let data = {modalShow: !modalShow};

        if (sendData && newChosenDate !== null) {

            data.newChosenDate = null;
            data.sendData = false;
            const unixTime = Math.floor(newChosenDate.getTime() / 1000);
            const obj = {handler_type, _xfConfirm: 1, 'auctions[0]': auction_id, sklad_date: unixTime};

            setParamsInlineBlock(obj).then((json) => {
                const {_redirectStatus} = json;

                if ('error' in json) {
                    alert(json.error[0]);
                    return
                }

                if (_redirectStatus === 'ok') {
                    dispatch(getLotData())
                }
            });

            dispatch(setParamsSetDateModal(data));
        }
    }
}

export const setLotNewEndTime = () => {

    return async (dispatch, getState) => {
        const {auction: {auction_id}} = getState().lotPage;

        const {modalShow, newChosenTime, sendTime} = getState().modalSetEndTime
        let data = {modalShow: !modalShow};

        if (sendTime && newChosenTime !== null) {

            data.newChosenTime = null;
            data.sendTime = false;

            const obj = {handler_type: 23, _xfConfirm: 1, 'auctions[0]': auction_id, time: newChosenTime};

            setParamsInlineBlock(obj).then((json) => {
                const {_redirectStatus} = json;

                if ('error' in json) {
                    alert(json.error[0]);
                    return
                }

                if (_redirectStatus === 'ok') {
                    dispatch(getLotData())
                }
            });
        }
        dispatch(setParamsSetEndTimeModal(data));
    }
}

export const setLotTitle = () => {
    return async (dispatch, getState) => {

        const {auction: {auction_id}, prefixes} = getState().lotPage;
        const {title: lot_title, category_id, prefix_id} = getState().modalSetTitle;

        const check = () => {
            const categoryPrefixes = prefixes[String(category_id)]
            const prefix = (prefix_id === 0 && !isUndefined(categoryPrefixes));
            const category = (category_id === 0);
            const title = (lot_title.length === 0);

            return {prefix, category, title};
        }

        const errorObj = check();

        dispatch(setParamsTitleModal({errors: errorObj}));

        if (includes(errorObj, true)) {
            return;
        }
        const obj = {_xfConfirm: 1, title: lot_title, prefix_id, auction_id, category_id}


        return await lotPageApiService.saveLotDetails(obj).then((json) => {
            const {_redirectStatus} = json

            if (_redirectStatus === 'ok') {
                dispatch(setParamsTitleModal({modalShow: false}));
            }

            dispatch(getLotData())
        });
    }
}

export const removeLot = () => {
    return async (dispatch, getState) => {
        const {phrases: {ak_au_no_reason}} = getState().lots;
        const {auction: {auction_id}} = getState().lotPage;
        const {delete_type, delete_reason} = getState().modalRemoveLot;

        if (delete_type === null) {
            return;
        }
        const reason = (delete_reason.length === 0) ? ak_au_no_reason : delete_reason;
        const obj = {
            handler_type: 3, 'auctions[0]': auction_id, 'hard_delete': delete_type, _xfConfirm: 1, reason
        };

        setParamsInlineBlock(obj).then((json) => {
            const {_redirectStatus} = json;

            if ('error' in json) {
                alert(json.error[0]);
                return
            }

            if (_redirectStatus === 'ok') {
                dispatch(getLotData())
            }
        });
        dispatch(setParams({modalShow: false, delete_type: null, delete_reason: ''}));
    }
}

export const restoreLot = () => {
    return async (dispatch, getState) => {
        const {auction: {auction_id}} = getState().lotPage;
        const obj = {
            handler_type: 4, 'auctions[0]': auction_id, _xfConfirm: 1
        };

        setParamsInlineBlock(obj).then((json) => {
            const {_redirectStatus} = json;

            if ('error' in json) {
                alert(json.error[0]);
                return
            }

            if (_redirectStatus === 'ok') {
                dispatch(getLotData())
            }
        });
    }
}
export const finishLot = () => {
    return async (dispatch, getState) => {
        const {auction: {auction_id}} = getState().lotPage;
        const obj = {
            handler_type: 14, 'auctions[0]': auction_id, _xfConfirm: 1
        };

        setParamsInlineBlock(obj).then((json) => {
            const {_redirectStatus} = json;

            if ('error' in json) {
                alert(json.error[0]);
                return
            }

            if (_redirectStatus === 'ok') {
                dispatch(getLotData())
            }
        });

        dispatch(setParamsFinishLotModal({modalShow: false}))
    }
}

export const removeLotFromSale = () => {
    return async (dispatch, getState) => {
        const {auction: {auction_id: id}} = getState().lotPage;
        const {type} = getState().modalRemoveFromSale;

        const obj = {_xfConfirm: 1, id, type}

        return await processApiService.saleRemove(obj).then((json) => {
            const {_redirectStatus} = json;

            if ('error' in json) {
                alert(json.error[0]);
                return
            }

            if (_redirectStatus === 'ok') {
                dispatch(getLotData())
            }

            dispatch(setParamsRemoveLotFromSale({modalShow: false, type: 0}))
            dispatch(getLotData())
        });
    }
}

export const deleteBid = (id) => {
    return async (dispatch) => {
        const obj = {_xfConfirm: 1, id}
        lotsApiService.deleteBid(obj).then((json) => {
            const {_redirectStatus} = json;

            if ('error' in json) {
                alert(json.error[0]);
                return
            }

            if (_redirectStatus === 'ok') {
                dispatch(getLotData())
            }
        });
    }
}

export const deleteAutoBid = (id) => {
    return async (dispatch) => {
        const obj = {_xfConfirm: 1, id}

        lotsApiService.deleteAutoBid(obj).then((json) => {
            const {_redirectStatus} = json;

            if ('error' in json) {
                alert(json.error[0]);
                return
            }

            if (_redirectStatus === 'ok') {
                dispatch(getLotData())
            }
        });
    }
}

export const setStatusNotSold = (auction_id) => {
    return async (dispatch) => {

        lotPageApiService.setStatusNotSold({auction_id, _xfConfirm: 1}).then((json) => {
            const {_redirectStatus} = json;

            if ('error' in json) {
                alert(json.error[0]);
                return
            }

            if (_redirectStatus === 'ok') {
                dispatch(getLotData())
            }
        })
    }
}

export const updateCountUsersWatchingLot = (id) => {
    return async (dispatch) => {
        const obj = {_xfConfirm: 1, id}

        processApiService.updateCountUsersWatchingLot(obj).then((json) => {
            const {_redirectStatus} = json;

            if ('error' in json) {
                alert(json.error[0]);
                return
            }

            if (_redirectStatus === 'ok') {
                dispatch(getLotData())
            }
        });
    }
}
