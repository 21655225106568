import React, {useContext, useMemo} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getFormattedDate} from "../../../redux/lots/action";
import {LotsContext} from "../../auction-lots-listing/auction-lots-listing ";
import {includes} from "lodash/collection";
import {lotContext} from "../../auction-lots-listing/components/lots-list-item/lots-list-item";

const DayOffBlock = () => {

    const dispatch = useDispatch();
    const {type} = useContext(LotsContext);
    const {lotData} = useContext(lotContext);

    const {
        date_of_sale,
        first_history_date,
        bid_date,
        expiration_date,
        waitforpay_date,
        send_date,
        take_date,
    } = lotData;

    const {
        phrases: {ak_au_lot_end_date, ak_au_ph_date_of_bet},
        time_wait_send,
        time_wait_pay,
        time_wait_take
    } = useSelector(state => state.lots);


    const time = useMemo(() => {

        if (includes(['for_sales', 'watch_lots', 'unfinished', 'no_pay', 'nosale', 'finalized', 'vacation', 'inactive_copy'], type)) {
            return dispatch(getFormattedDate(expiration_date))
        }

        if (type === 'no_sale') {
            return dispatch(getFormattedDate(first_history_date))
        }

        if (type === 'all_user_bets') {
            return dispatch(getFormattedDate(bid_date))
        }
        if (includes(['awaiting_confirmation', 'take'], type)) {
            return dispatch(getFormattedDate(parseInt(take_date) + time_wait_take))
        }

        if (includes(['awaiting_shipment', 'send'], type)) {
            return dispatch(getFormattedDate(parseInt(send_date) + time_wait_send))
        }

        if (includes(['awaiting_payment', 'waitforpay'], type)) {
            return dispatch(getFormattedDate(parseInt(waitforpay_date) + time_wait_pay))
        }

        return dispatch(getFormattedDate(date_of_sale))

    }, [type])

    return (
        <div className='d-flex flex-column mt-1'>
            <span title={type === 'all_user_bets' ? ak_au_ph_date_of_bet : ak_au_lot_end_date} className='dayOff'>
                {time}
            </span>
        </div>
    )
}
export default DayOffBlock;