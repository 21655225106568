import React, {useCallback, useMemo, useState} from "react";
import {deleteSessionStorageData} from "../../../../redux/crud-lot/actionCrudLot";
import {find} from "lodash/collection";
import GlobalHelper from "../../../../helpers/global-helper";
import {useDispatch} from "react-redux";
import ModalCreateLotContent from "./modal-create-lot-content";
import ModalBlackBacground from '../../../repeating-components/wrapper-components/modal-black-background';
import {useNavigate} from "react-router-dom";

const ModalCreateLot = (props) => {

    const {
        phrases: {ak_select_cat, ak_au_change},
        all_categories,
        handleShow,
        show
    } = props

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [categoryId, setCategoryId] = useState(0)
    const [error, setError] = useState(false)

    const setCurrentCategoryId = (categoryId) => {
        setCategoryId(categoryId);
        setError(false);
    }

    const categoryLink = useCallback(() => {
        if (!categoryId) {
            setError(true);
            return false;
        }
        const item = find(all_categories, {auction_category_id: categoryId});
        return GlobalHelper.getCategoryVariableReference(item);
    }, [categoryId]);

    const sendForm = () => {

        const link = categoryLink();

        if (!link) {
            return false;
        }
        handleShow();
        dispatch(deleteSessionStorageData())
        navigate(`/auction-process/create/${link}`);
    }

   const modalCreateLotContent = useMemo(() => {
        return <ModalCreateLotContent
            setCurrentCategoryId={setCurrentCategoryId}
            error={error}
        />
    }, [error, categoryId]);

    return (

        <ModalBlackBacground
            setShow={handleShow}
            show={show}
            sendForm={sendForm}
            phraseTitle={ak_select_cat}
            phraseSubmit={ak_au_change}
        >{modalCreateLotContent}
        </ModalBlackBacground>

    )

}
export default ModalCreateLot