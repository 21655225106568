import React from 'react';
import {LazyLoadImage} from 'react-lazy-load-image-component';
import {Icon} from "@iconify/react";

const areEqual = (prevProps, nextProps) => {
    // Perform shallow comparison on props that affect rendering
    return (
        prevProps.title === nextProps.title &&
        prevProps.imgUrl === nextProps.imgUrl &&
        prevProps.linkUrl === nextProps.linkUrl &&
        prevProps.rel === nextProps.rel
    );
};

const LotThumbnail = React.memo(
    ({setShow, title = '', imgUrl = '', linkUrl = '', rel = undefined}) => {

        const showImage = (e) => {
            e.preventDefault();
            setShow && setShow(); // Call setShow only if it's a function
        };
        return (
            <div className="product-list-img m-2">
                <a href={!linkUrl.length ? imgUrl : linkUrl} data-action rel={rel ? rel : undefined}
                   onClick={(e) => {
                       showImage(e)
                   }}>
                    <LazyLoadImage
                        className='preview img-fluid img-thumbnail shadow'
                        alt={title}
                        src={imgUrl}
                        data-action/>
                </a>
                <div className="product-quickview">
                    <a className='d-flex justify-content-center' href={!linkUrl.length ? imgUrl : linkUrl} data-action rel={rel ? rel : undefined}
                       onClick={(e) => {
                           showImage(e)
                       }}>
                        <i data-action className="sli sli-magnifier-add align-self-center"><Icon icon="uil:search-plus" /></i>
                    </a>
                </div>
            </div>
        );

    }, areEqual);

export default LotThumbnail