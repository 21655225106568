import {
    ON_CHANGE_ACTION,
    ON_CHANGE_CATEGORY,
    ON_CHANGE_COPY_ACTION,
    ON_CHANGE_REMOVE_ACTION,
    SET_ERRORS, SET_OPTIONS, TOGGLE_MODAL
} from "../../types";
import {every, map, some, sortBy} from "lodash/collection";

import ReibertApiService from '../../../services/reibert-api-service';
import {onToggleAllLots, updateLotsData} from "../../lots/action";
import {setParamsModalPrefix} from "../modal-prefix/actionModalPrefix";
import {setParamsPromotionLots} from "../modal-promotion-lots/actionPromotionLots";
import {setParamsSetDateModal} from "../modal-set-date/actionSetDate";
import {setParamsTradingDurationModal} from "../modal-trading-duration/actionTradingDuration";
import {setParamsSetEndTimeModal} from "../modal-set-end-time/actionSetEndTime";
import InlineModApiService from "../../../services/inline-mod-api-service";
import {isNull} from "lodash/lang";
import {getQueryLinkParams} from "../../../helpers/routing-helper";

// ���� ������� ����� ����� �� � �����
export function onChangeAction(handler_type = 0) {
    return dispatch => {
        dispatch({type: ON_CHANGE_ACTION, payload: handler_type})
    }
}

export function onChangeCategory(category_id) {
    return dispatch => {
        dispatch({type: ON_CHANGE_CATEGORY, payload: category_id})
    }
}

// ����� ��������� �������� ��� �������� �����
export function onChangeCopyAction(operation_copy) {
    return dispatch => {
        dispatch({type: ON_CHANGE_COPY_ACTION, payload: operation_copy})
    }
}

// ����� ��� �������� ����
export function onChangeRemoveAction(obj) {
    return dispatch => {
        dispatch({type: ON_CHANGE_REMOVE_ACTION, payload: obj})
    }
}

export function setErrors(arr = []) {
    return dispatch => {
        dispatch({type: SET_ERRORS, payload: arr})
    }
}

export function toggleModal(type) {
    return dispatch => {
        dispatch({type: TOGGLE_MODAL, payload: type})
    }
}

export function setOption() {
    return (dispatch, getState) => {

        const {
            changeLots,
            visitor: {
                user_id: visitor_user_id,
                permissions: {
                    auctions: {
                        RestoreLots,
                        right_to_move_lot,
                        view_all_bids,
                        editOthersAuctions,
                        deleteLots
                    }
                }
            },
            phrases: {
                ak_au_move_lots,
                ak_au_phrase_enable_auto_reposition,
                ak_au_phrase_disabled_auto_reposition,
                ak_au_enable_interesting_lots,
                ak_au_disabled_interesting_lots,
                ak_au_enable_promotion_lots,
                ak_au_apply_category_prefix,
                ak_au_recreate_and_enable_auto_reposting,
                ak_au_new_reedit,
                expire_auction,
                ak_au_dell_lots,
                ak_au_restore_lots,
                ak_au_create_copy_lots,
                ak_au_hide_lot,
                ak_au_show_lot,
                ak_au_ph_edit_end_date,
                ak_au_ph_change_duration_auction,
                ak_au_ph_lot_price_offer,
                ak_au_phrase_turn_on,
                ak_au_phrase_turn_off,
                ak_au_only_bets_positive_balance,
                ak_au_ph_edit_end_time,
                ak_au_ph_only_not_ignore,
                ak_au_ph_only_over_one_month
            }
        } = getState().lots;

        const {type} = getQueryLinkParams()

        const option = [

            {
                value: 1,
                position: 8,
                phrase: ak_au_phrase_enable_auto_reposition,
                view: () => {
                    return (
                        every(changeLots, {'status': 'active'}) &&
                        (
                            {view_all_bids} || every(changeLots, {'user_id': visitor_user_id})
                        )) ? true : false
                }
            },

            {
                value: 2,
                position: 9,
                phrase: ak_au_phrase_disabled_auto_reposition,
                view: () => {
                    return (
                        every(changeLots, {'status': 'active'}) &&
                        (
                            view_all_bids || every(changeLots, {'user_id': visitor_user_id})
                        )) ? true : false
                }
            },

            {
                value: 3,
                position: 2,
                phrase: ak_au_dell_lots,
                view: () => {
                    return ((every(changeLots, {'user_id': visitor_user_id}) || view_all_bids) &&
                        ((every(changeLots, {'status': 'nosale'})) || (every(changeLots, {'status': 'sale'})) ||
                            (every(changeLots, {'status': 'finalized'})) || (every(changeLots, {'status': 'active'}))) &&
                        deleteLots
                    ) ? true : false
                }
            },


            {
                value: 4,
                position: 2,
                phrase: ak_au_restore_lots,
                view: () => {
                    return ((every(changeLots, {'user_id': visitor_user_id}) || view_all_bids) &&
                        ((every(changeLots, {'status': 'nosale'})) || (every(changeLots, {'status': 'sale'})) ||
                            (every(changeLots, {'status': 'finalized'})) || (every(changeLots, {'status': 'active'}))) &&
                        (RestoreLots && some(changeLots, {'isdel': 1}))
                    ) ? true : false
                }
            },

            {
                value: 5,
                position: 1,
                phrase: ak_au_move_lots,
                view: () => {
                    return (every(changeLots, {'status': 'active'}) && right_to_move_lot)
                        ? true : false
                },

            },

            {
                value: 6,
                position: 2,
                phrase: ak_au_new_reedit,
                view: () => {
                    return ((every(changeLots, {'user_id': visitor_user_id}) || view_all_bids) &&
                        (every(changeLots, {'status': 'nosale'}) &&
                            (every(changeLots, {'expired_flag': 3}) || every(changeLots, {'expired_flag': 2})))
                    ) ? true : false
                },

            },
            {
                value: 7,
                position: 2,
                phrase: ak_au_recreate_and_enable_auto_reposting,
                view: () => {
                    return ((every(changeLots, {'user_id': visitor_user_id}) || view_all_bids) &&
                        (every(changeLots, {'status': 'nosale'}) &&
                            (every(changeLots, {'expired_flag': 3}) || every(changeLots, {'expired_flag': 2})))
                    ) ? true : false
                },

            },

            {
                value: 8,
                position: 2,
                phrase: expire_auction,
                view: () => {
                    return ((every(changeLots, {'user_id': visitor_user_id}) || view_all_bids) &&
                        (every(changeLots, {'status': 'nosale'}))
                    ) ? true : false
                },

            },

            {
                value: 9,
                position: 6,
                phrase: ak_au_enable_interesting_lots,
                view: () => {
                    return (every(changeLots, {'status': 'active'}) && view_all_bids)
                        ? true : false
                },

            },
            {
                value: 10,
                position: 7,
                phrase: ak_au_disabled_interesting_lots,
                view: () => {
                    return (every(changeLots, {'status': 'active'}) && view_all_bids)
                        ? true : false
                },

            },

            {
                value: 11,
                position: 2,
                phrase: ak_au_create_copy_lots,
                view: () => {
                    return (every(changeLots, {'user_id': visitor_user_id}) &&
                        ((every(changeLots, {'status': 'nosale'})) || (every(changeLots, {'status': 'sale'})) ||
                            (every(changeLots, {'status': 'finalized'})))
                    ) ? true : false
                },

            },

            {
                value: 12,
                position: 5,
                phrase: ak_au_enable_promotion_lots,
                view: () => {
                    return (every(changeLots, {'status': 'active'}) &&
                        (type === 'for_sales' && every(changeLots, {'user_id': visitor_user_id}))
                    )
                        ? true : false
                },

            },

            {
                value: 13,
                position: 4,
                phrase: ak_au_apply_category_prefix,
                view: () => {
                    return (every(changeLots, {'status': 'active'}) && editOthersAuctions)
                        ? true : false
                },

            },
            {
                value: 14,
                position: 2,
                phrase: expire_auction,
                view: () => {
                    return (every(changeLots, {'status': 'active'}) && view_all_bids)
                        ? true : false
                },

            },
            {
                value: 15,
                position: 2,
                phrase: ak_au_hide_lot,
                view: () => {

                    if (every(changeLots, {'status': 'sale'})) {
                        if (view_all_bids || every(changeLots, {'user_id': visitor_user_id})) {
                            return true
                        }
                    }
                    return false
                },

            },
            {
                value: 16,
                position: 2,
                phrase: ak_au_show_lot,
                view: () => {
                    if (every(changeLots, {'status': 'sale'})) {
                        if (view_all_bids || every(changeLots, {'user_id': visitor_user_id})) {
                            return true
                        }
                    }
                    return false
                },
            },
            {
                value: 17,
                position: 14,
                phrase: ak_au_ph_edit_end_date,
                view: () => {
                    return (
                        every(changeLots, {'status': 'active'}) &&
                        ({view_all_bids} || every(changeLots, {'user_id': visitor_user_id})
                        )) ? true : false
                }
            },
            {
                value: 18,
                position: 15,
                phrase: ak_au_ph_change_duration_auction,
                view: () => {
                    return (
                        every(changeLots, {'status': 'active'}) &&
                        ({view_all_bids} || every(changeLots, {'user_id': visitor_user_id})
                        )) ? true : false
                }
            },
            {
                value: 19,
                position: 10,
                phrase: ak_au_ph_lot_price_offer.replace(/\{phrase\}/, ak_au_phrase_turn_on),
                view: () => {
                    return (
                        every(changeLots, {'status': 'active'}) &&
                        ({view_all_bids} || every(changeLots, {'user_id': visitor_user_id})
                        )) ? true : false
                }
            },
            {
                value: 20,
                position: 11,
                phrase: ak_au_ph_lot_price_offer.replace(/\{phrase\}/, ak_au_phrase_turn_off),
                view: () => {
                    return (
                        every(changeLots, {'status': 'active'}) &&
                        ({view_all_bids} || every(changeLots, {'user_id': visitor_user_id})
                        )) ? true : false
                }
            },
            {
                value: 21,
                position: 12,
                phrase: ak_au_only_bets_positive_balance.replace(/\{phrase\}/, ak_au_phrase_turn_on),
                view: () => {
                    return (
                        every(changeLots, {'status': 'active'}) &&
                        ({view_all_bids} || every(changeLots, {'user_id': visitor_user_id})
                        )) ? true : false
                }
            },
            {
                value: 22,
                position: 13,
                phrase: ak_au_only_bets_positive_balance.replace(/\{phrase\}/, ak_au_phrase_turn_off),
                view: () => {
                    return (
                        every(changeLots, {'status': 'active'}) &&
                        ({view_all_bids} || every(changeLots, {'user_id': visitor_user_id})
                        )) ? true : false
                }
            },
            {
                value: 23,
                position: 15,
                phrase: ak_au_ph_edit_end_time,
                view: () => {
                    return (
                        every(changeLots, {'status': 'active'}) &&
                        ({view_all_bids} || every(changeLots, {'user_id': visitor_user_id})
                        )) ? true : false
                }
            },
            {
                value: 24,
                position: 13,
                phrase: ak_au_ph_only_not_ignore.replace(/\{phrase\}/, ak_au_phrase_turn_on),
                view: () => {
                    return (
                        every(changeLots, {'status': 'active'}) &&
                        ({view_all_bids} || every(changeLots, {'user_id': visitor_user_id})
                        )) ? true : false
                }
            },
            {
                value: 25,
                position: 13,
                phrase: ak_au_ph_only_not_ignore.replace(/\{phrase\}/, ak_au_phrase_turn_off),
                view: () => {
                    return (
                        every(changeLots, {'status': 'active'}) &&
                        ({view_all_bids} || every(changeLots, {'user_id': visitor_user_id})
                        )) ? true : false
                }
            },
            {
                value: 26,
                position: 13,
                phrase: ak_au_ph_only_over_one_month.replace(/\{phrase\}/, ak_au_phrase_turn_on),
                view: () => {
                    return (
                        every(changeLots, {'status': 'active'}) &&
                        ({view_all_bids} || every(changeLots, {'user_id': visitor_user_id})
                        )) ? true : false
                }
            },
            {
                value: 27,
                position: 13,
                phrase: ak_au_ph_only_over_one_month.replace(/\{phrase\}/, ak_au_phrase_turn_off),
                view: () => {
                    return (
                        every(changeLots, {'status': 'active'}) &&
                        ({view_all_bids} || every(changeLots, {'user_id': visitor_user_id})
                        )) ? true : false
                }
            },
        ];

        dispatch({type: SET_OPTIONS, payload: sortBy(option, ['position'])})
    }
}

export function onSubmitForm() {
    return async (dispatch, getState) => {

        const {setParamsInlineBlock} = new InlineModApiService();
        const {formPrefixData} = new ReibertApiService();

        const {
            changeLots,
            phrases: {
                ak_au_ph_select_type_deletion,
                ak_au_no_reason,
                ak_au_ph_choose_category,
                ak_au_operation_copy_error,
                ak_au_ph_choose_operation
            }
        } = getState().lots;

        const {handler_type, category_id, operation_copy, delete_type, delete_reason} = getState().modal;

        const getObj = (obj = {}) => {
            return {
                ...obj,
                handler_type: handler_type,
                auctions: JSON.stringify(map(changeLots, 'auction_id'))
            };
        }

        const runDispach = () => {
            dispatch(onToggleAllLots(false));
            dispatch(updateLotsData())
            dispatch(onChangeAction(0))
        };

        const handler_types = [1, 2, 4, 6, 7, 8, 9, 10, 14, 15, 16, 19, 20, 21, 22, 24, 25, 26, 27];

        if (handler_types.includes(handler_type)) {

            let obj = getObj()
            setParamsInlineBlock(obj).then(({_redirectStatus}) => {
                if (_redirectStatus === 'ok') {
                    runDispach()
                }
            });
        } else {
            switch (handler_type) {
                case 3:
                    if (delete_type === null) {
                        dispatch(setErrors([
                            {phrase: ak_au_ph_select_type_deletion}
                        ]));
                    } else {
                        const reason = (delete_reason.length === 0) ? ak_au_no_reason : delete_reason;
                        let obj = getObj({hard_delete: delete_type, reason});
                        setParamsInlineBlock(obj).then(({_redirectStatus}) => {
                            if (_redirectStatus === 'ok') {
                                runDispach()
                                dispatch(onChangeRemoveAction({delete_type: null, delete_reason: ''}));
                            }
                        });
                    }
                    break;
                case 5:
                    if (category_id === null) {
                        dispatch(setErrors([{phrase: ak_au_ph_choose_category}]));
                    } else {
                        let obj = getObj({new_category_id: category_id})
                        setParamsInlineBlock(obj).then(({_redirectStatus}) => {
                            if (_redirectStatus === 'ok') {
                                runDispach()
                            }
                        });
                    }
                    break;
                case 11:
                    if (operation_copy === null) {
                        dispatch(setErrors([{phrase: ak_au_operation_copy_error}]));
                    } else {
                        let obj = getObj({operation_copy: operation_copy});
                        setParamsInlineBlock(obj).then(({_redirectStatus}) => {
                            if (_redirectStatus === 'ok') {
                                runDispach()
                            }
                        });
                    }
                    break;
                case 12:
                    setParamsInlineBlock(getObj()).then((json) => {
                        json.modalShow = true
                        dispatch(setParamsPromotionLots(json))
                    }).then(() => {
                        dispatch(onToggleAllLots(false));
                        dispatch(onChangeAction(0))
                    })
                    break;
                case 13:
                    formPrefixData(getObj()).then((json) => {
                        json.modalShow = true;
                        dispatch(setParamsModalPrefix(json))
                    }).then(() => {
                        dispatch(onToggleAllLots(false));
                        dispatch(onChangeAction(0))
                    })
                    break;
                case 17:
                    const {sendData, newChosenDate, modalShow} = getState().modalSetDate;
                    let data = {modalShow: !modalShow};

                    if (sendData && newChosenDate !== null) {

                        data.newChosenDate = null;
                        data.sendData = false;

                        const unixTime = Math.floor(newChosenDate.getTime() / 1000);

                        let obj = getObj({sklad_date: unixTime});

                        setParamsInlineBlock(obj).then(({_redirectStatus}) => {
                            if (_redirectStatus === 'ok') {
                                runDispach()
                            }
                        });
                    }

                    dispatch(setParamsSetDateModal(data));
                    break;
                case 18:
                    const sendFormTradingDuration = () => {
                        const {amountDay: amount_day, sendData, modalShow} = getState().modalTradingDuration;
                        let data = {modalShow: !modalShow};

                        if (sendData && isNull(amount_day)) {

                            data.amountDay = null;
                            data.sendData = false;

                            let obj = getObj({amount_day});
                            setParamsInlineBlock(obj).then(({_redirectStatus}) => {
                                if (_redirectStatus === 'ok') {
                                    runDispach()
                                }
                            });
                        }

                        dispatch(setParamsTradingDurationModal(data));
                    }

                    sendFormTradingDuration();
                    break;

                case 23:
                    const sendFormSetEndTime = () => {
                        const {modalShow, sendTime, newChosenTime} = getState().modalSetEndTime;
                        let data = {modalShow: !modalShow};

                        if (sendTime && newChosenTime !== null) {

                            data.newChosenTime = null;
                            data.sendTime = false;

                            let obj = getObj({time: newChosenTime})

                            setParamsInlineBlock(obj).then(({_redirectStatus}) => {
                                if (_redirectStatus === 'ok') {
                                    runDispach()
                                }
                            });
                        }
                        dispatch(setParamsSetEndTimeModal(data));
                    }
                    sendFormSetEndTime();
                    break;

                default:
                    dispatch(setErrors([{phrase: ak_au_ph_choose_operation}]));
                    break;
            }
        }
    }
};
