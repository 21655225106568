import React, {useState} from "react";
import {useSelector} from "react-redux";
import {find, groupBy} from "lodash/collection";
import {reverse, last, head, findIndex} from "lodash/array";
import {isUndefined} from "lodash/lang";
import './modal-create-lot.css'
import {Alert, Dropdown} from "react-bootstrap";
import {getQueryLinkParams} from "../../../../helpers/routing-helper";

const ModalCreateLotContent = (props) => {

    const {auction_category_id} = getQueryLinkParams();
    const [categoryId, setCategory] = useState(auction_category_id);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [blockData, setBlockData] = useState([]);
    const {setCurrentCategoryId, error} = props

    const {
        phrases: {
            ak_select_cat, ak_au_LableDiscription_Create_category, ak_select_main_cat, ak_au_LableSelectCategory,
            ak_au_phrase_second_category, ak_au_phrase_change_category, ak_au_LableSelectCategoryFirst, ak_au_no_matching_subcategory
        },
        all_categories
    } = useSelector(state => state.lots);


    const getSelectedCategories = () => {
        let allSelectedCategories = [categoryId]

        const getCategories = (category_id) => {
            const {parent_category_id, auction_category_id} = find(all_categories, {auction_category_id: category_id});
            if (auction_category_id !== categoryId) {
                allSelectedCategories = [...allSelectedCategories, auction_category_id];
            }
            if (parent_category_id !== 0) {
                getCategories(parent_category_id)
            }
        }

        if (categoryId) {
            getCategories(categoryId)
        }

        return reverse(allSelectedCategories);
    }

    React.useEffect(() => {
        setCategory(auction_category_id)
        setCurrentCategoryId(auction_category_id)
    }, [auction_category_id])

    React.useEffect(() => {
        setSelectedCategories(getSelectedCategories())
    }, [categoryId])

    let objParentCategories = groupBy(all_categories, 'parent_category_id');

    const createBlockCategories = (parent_category_id, defaultSelect) => {

        const getDescriptionBlock = () => {
            if (defaultSelect) {
                const {category_description} = find(all_categories, {auction_category_id: defaultSelect});
                return category_description
            }
            return null
        }

        const description = getDescriptionBlock()
        const firstEl = head(selectedCategories)

        const onChangeCategory = (e) => {
            setCurrentCategoryId(parseInt(e.target.value))
            setCategory(parseInt(e.target.value))

        }

        const getOptions = () => {
            return objParentCategories[parent_category_id].map((val, key) => {
                const {auction_category_id, category_title} = val

                if (auction_category_id === 56){
                    return
                }

                return (
                    <option key={key} value={auction_category_id}>
                        {category_title}
                    </option>
                )
            })
        }

        const getDeleteButtoon = () => {

            if (firstEl === defaultSelect) {
                return null
            }

            const removeBlockCategories = () => {
                const el = findIndex(blockData, {parent_category_id})
                const newBlockData = [...blockData.slice(0, el)]
                const {defaultSelect: currentCategory} = last(newBlockData)
                setCurrentCategoryId(currentCategory)
                setBlockData(newBlockData);
            }

            return (
                <button onClick={() => removeBlockCategories()}
                        type="button"
                        className="btn btn-outline-secondary btn-sm ml-2">
                    <span aria-hidden="true">&times;</span>
                </button>
            );
        }

        const phraseLabel = (firstEl === defaultSelect) ? ak_select_main_cat : ak_au_phrase_second_category

        const optionNoMatchingSubCategory = (parent_category_id !== 0)
            ? <optgroup label="----------------" className="divider"><option value={parent_category_id}>{ak_au_no_matching_subcategory}</option></optgroup>
            : null;

        return (
            <div className="col-12 no-gutters mb-3">
                <div className="col-lg-6 col-10 ">
                    <label className='title font-weight-bold'>{phraseLabel}</label>
                    <div className='d-flex'>
                        <select
                            value={defaultSelect}
                            onChange={(e) => onChangeCategory(e)}
                            className="custom-select custom-select-sm">
                            <option value={0}>{ak_au_phrase_change_category}</option>
                            {getOptions()}
                            {optionNoMatchingSubCategory}

                        </select>
                        {getDeleteButtoon()}
                    </div>
                </div>
                <span className="form-text font-italic text-secondary">
                    {description}
                </span>
            </div>
        )
    }

    const getBlockCategories = () => {
        let block = selectedCategories.map((val, key) => {
            if (val === 0) {
                return {parent_category_id: 0, defaultSelect: val}
            }

            const {parent_category_id} = find(all_categories, {auction_category_id: val});
            return {parent_category_id, defaultSelect: val}
        })

        const lastEl = last(selectedCategories)

        if (lastEl !== 0 && !isUndefined(objParentCategories[lastEl])) {
            block = [...block, {parent_category_id: lastEl, defaultSelect: 0}];
        }
        return block
    }

    React.useEffect(() => {
        const block = getBlockCategories()
        setBlockData(block)
    }, [selectedCategories, categoryId])

    if (selectedCategories.length === 0 || blockData.length === 0) {
        return null
    }

    const createBlocks = () => {
        return blockData.map((item, key) => {
            const {parent_category_id, defaultSelect} = item
            return (
                <React.Fragment key={key}>
                    {createBlockCategories(parent_category_id, defaultSelect)}
                </React.Fragment>
            )
        })
    }

    const errorBlock = (!error)
        ? null : <Alert className='col-12 no-gutters mb-3' variant={'danger'}>{ak_select_cat}</Alert>

    return (
        <form id='form-create-lot' name='form-create-lot'>
            <div id='create-lot' className="form-row">
                {errorBlock}
                <div className="col-12 no-gutters mb-3">
             <span className="form-text">
                    {ak_au_LableDiscription_Create_category}
                </span>
                </div>
                {createBlocks()}
                <div className="col-12 no-gutters">
             <span className="form-text ">
                    {ak_au_LableSelectCategoryFirst}
                </span>
                    <span className="form-text">
                    {ak_au_LableSelectCategory}
                </span>
                </div>
            </div>
        </form>
    )
}

export default ModalCreateLotContent