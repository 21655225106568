import React, {useMemo, useState} from "react";
import {useSelector} from "react-redux";

import InitialButtonCreateLot from "./initial-button-create-lot";
import {isUndefined} from "lodash/lang";
import ModalCreateLot from "./modal-create-lot";

const ButtonCreateLot = () => {

    const [show, setShow] = useState(false);
    const handleShow = () => setShow(!show);

    const {lots} = useSelector((state) => {
        return state
    });

    const {visitor: {permissions, credits}} = lots
    const {auctions: {createAuctions}} = permissions

    const modal = useMemo(() => {

        if (!show){
            return null;
        }

        return <ModalCreateLot {...lots} handleShow={handleShow} show={show}/>

    }, [show]);

    const can_create_new_lot = parseInt(document.getElementById('can_create_new_lot').value);

    if (!can_create_new_lot) {
        return null
    }

    if (!createAuctions) {
        return null;
    }

    if (isUndefined(credits)) {
        return null
    }

    return (
        <React.Fragment>
            {modal}
            <InitialButtonCreateLot {...lots} handleShow={handleShow}/>
        </React.Fragment>
    )

}
export default ButtonCreateLot