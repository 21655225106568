
import React, { useState, useEffect, useRef } from 'react';
import './countdown.css';
import {useSelector} from "react-redux";

const Countdown = ({ date }) => {
    const {phrases: {days:ph_days, day:ph_day,}} = useSelector(state => state.lots);

    const [days, setDays] = useState(0);
    const [hours, setHours] = useState(0);
    const [min, setMin] = useState(0);
    const [sec, setSec] = useState(0);
    const intervalRef = useRef(null);

    const calculateCountdown = () => {
        // let diff = ((endDate * 1000) - Date.parse(new Date())) / 1000;
        let diff = Math.max(((date * 1000) - Date.parse(new Date())) / 1000);

        if (diff <= 0) return false;

        const timeLeft = {
            years: 0,
            days: 0,
            hours: 0,
            min: 0,
            sec: 0,
        };

        if (diff >= (365.25 * 86400)) { // 365.25 * 24 * 60 * 60
            timeLeft.years = Math.floor(diff / (365.25 * 86400));
            diff -= timeLeft.years * 365.25 * 86400;
        }
        if (diff >= 86400) { // 24 * 60 * 60
            timeLeft.days = Math.floor(diff / 86400);
            diff -= timeLeft.days * 86400;
        }
        if (diff >= 3600) { // 60 * 60
            timeLeft.hours = Math.floor(diff / 3600);
            diff -= timeLeft.hours * 3600;
        }
        if (diff >= 60) {
            timeLeft.min = Math.floor(diff / 60);
            diff -= timeLeft.min * 60;
        }
        timeLeft.sec = diff;

        setDays(timeLeft.days);
        setHours(timeLeft.hours);
        setMin(timeLeft.min);
        setSec(timeLeft.sec);
    };

    const addLeadingZeros = (value) => {
        // String(value).padStart(2, '0');

        value = String(value);
        while (value.length < 2) {
            value = '0' + value;
        }
        return value;
    }

    useEffect(() => {
        intervalRef.current = setInterval(calculateCountdown, 1000);
        return () => clearInterval(intervalRef.current);
    }, [date]);

    const ph = days === 1 ? ph_day : ph_days;

    const renderCountdown = () => (
        <div className="d-flex flex-wrap justify-content-center text-muted">
            <div className="d-inline-block mx-1">
                <div className="d-flex">
                    <span className="mr-1">{addLeadingZeros(days)}</span>
                    <span className="time-name">{ph}</span>
                </div>
            </div>

            <div className="d-inline-block">
        <span className="d-flex ">
          <span className="">{addLeadingZeros(hours)}</span>
          <span className="time-name mx-1">:</span>
          <span className="">{addLeadingZeros(min)}</span>
          <span className="time-name mx-1">:</span>
          <span className="">{addLeadingZeros(sec)}</span>
        </span>
            </div>
        </div>
    );

    return renderCountdown();
};

export default Countdown;
