import React, {useContext} from 'react';
import BuyerOrSallerBlock from "../buyer-or-saler-block";
import {useSelector} from "react-redux";
import {lotContext} from "../../auction-lots-listing/components/lots-list-item/lots-list-item";

const SellerBlock = (props) => {
    const {phrases:{seller}, users, rating_values} = useSelector(state => state.lots);
    const {lotData} = useContext(lotContext);
    const {user_id} = lotData;

    if (users[user_id] === undefined){
        return null
    }

    const {username, rating} = users[user_id];
    const {ext_rating} = rating_values[rating];

    return (
        <BuyerOrSallerBlock
            {...props}
            word={seller}
            name={username}
            user_id={user_id}
            rating={ext_rating}
        />
    );
}

export default SellerBlock