import React, {useMemo, memo, useContext} from 'react';
import {useSelector} from "react-redux";
import CheckBox from "./check-box";
import PrefixBlock from "./prefix-block";
import LinkTitle from "./link-title";
import './lot-title.css';
import BadgesBlock from "./badges -block";
import {LotsContext} from "../../auction-lots-listing/auction-lots-listing "
import {lotContext} from "../../auction-lots-listing/components/lots-list-item/lots-list-item";

const LotTitle = (props) => {
    const {lotData} = useContext(lotContext);
    const {user_lots_type} = useContext(LotsContext);

    const {
        visitor: {
            user_id: visitor_user_id,
            permissions: {
                auctions: {
                    deleteLots,
                    RestoreLots,
                    view_all_bids
                }
            }
        },
    } = useSelector(state => state.lots);

    const {auction_id, user_id: auction_user_id, status} = lotData; //todo �������� �� context

    const checkCondition = useMemo(() => {
        const statusType = ['nosale', 'sale', 'finalized', 'active'];
        return ((visitor_user_id === auction_user_id || deleteLots || RestoreLots || view_all_bids)
            && statusType.includes(status));

    }, [visitor_user_id, auction_user_id, deleteLots, RestoreLots, view_all_bids]);

    const {clazz_block, clazz_label} = useMemo(() => {
        let obj = {};

        obj.clazz_block = 'form-inline';
        obj.clazz_label = '';

        if (user_lots_type === 'archive_lots') {
            return obj
        }

        if (checkCondition) {
            obj.clazz_block = 'custom-control custom-checkbox';
            obj.clazz_label = 'custom-control-label';
        }
        return obj
    }, [user_lots_type, checkCondition]);

    const MemoizedPrefixBlock = memo(PrefixBlock);
    const MemoizedLinkTitle = memo(LinkTitle);
    const MemoizedBadgesBlock = memo(BadgesBlock);

    return (
        <div className={clazz_block}>
            {user_lots_type !== 'archive_lots' && checkCondition && <CheckBox {...props}/>}
            <label data-action className={clazz_label} htmlFor={`lot_${auction_id}_title`}>
                <div className="d-flex justify-content-center">
                    <div className="align-self-start flex-fill">
                        <MemoizedPrefixBlock/>
                        <MemoizedLinkTitle/>
                        <MemoizedBadgesBlock/>
                    </div>
                </div>
            </label>
        </div>
    );
};

export default LotTitle;